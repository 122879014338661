import React, { useState, useEffect } from "react";
import { FaHome,FaUserShield, FaImages, FaNewspaper, FaTrophy, FaFacebookF, FaTiktok, FaInstagram, FaYoutube, FaFolder, FaArrowLeft, FaEnvelope, FaPhone } from "react-icons/fa";
import { motion } from "framer-motion";

const ClassNewsPage = () => {
  const [activeSection, setActiveSection] = useState("home");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  const [selectedNews, setSelectedNews] = useState(null);
  const [showDetailedNews, setShowDetailedNews] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://sf-cdn.coze.com/obj/unpkg-va/flow-platform/chat-app-sdk/0.1.0-beta.7/libs/oversea/index.js";
    script.async = true;
    script.onload = () => {
      new window.CozeWebSDK.WebChatClient({
        config: {
          bot_id: '7430647161299730439',
        },
        componentProps: {
          title: 'Coze',
        },
      });
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const sections = [
    { id: "home", title: "Trang chủ", icon: <FaHome /> },
    { id: "memory", title: "Ảnh", icon: <FaImages /> },
    { id: "news", title: "Tin tức", icon: <FaNewspaper /> },
    { id: "achievements", title: "Thành tích", icon: <FaTrophy /> },
    {id: "admin", title: "Admin", icon: <FaUserShield /> },
  ];
  
  const socialLinks = [
    { id: "facebook", icon: <FaFacebookF />, url: "https://www.facebook.com/a5k66" },
    { id: "tiktok", icon: <FaTiktok />, url: "https://www.tiktok.com/@thptvinhlinha5k66" },
    { id: "instagram", icon: <FaInstagram />, url: "https://www.instagram.com/_a5.k66_/" },
    { id: "youtube", icon: <FaYoutube />, url: "https://www.youtube.com/@THPTVINHLINHA5-K66" },
  ];

  const albums = [
    { id: "march8", title: "Ảnh 20/10 - 8/3 Lớp 10", images: [
      { id: 1, src: "https://i.imgur.com/8deJO7r.jpeg", alt: "Ảnh 1" },
      { id: 2, src: "https://i.imgur.com/KUo9fCs.jpeg", alt: "Ảnh 2" },
      { id: 3, src: "https://i.imgur.com/VXFyz0X.jpeg", alt: "Ảnh 3" },
      { id: 4, src: "https://i.imgur.com/PIOhmoZ.jpeg", alt: "Ảnh 4" },
      { id: 5, src: "https://i.imgur.com/OxTYHij.jpeg", alt: "Ảnh 5" },
      { id: 6, src: "https://i.imgur.com/XIYyy8Y.jpeg", alt: "Ảnh 6" },
      { id: 7, src: "https://i.imgur.com/XKXzakY.jpeg", alt: "Ảnh 7" },
      { id: 8, src: "https://i.imgur.com/wGukApj.jpeg", alt: "Ảnh 8" },
      { id: 9, src: "https://i.imgur.com/jXIk9Ml.jpeg", alt: "Ảnh 9" },
    ]},
    { id: "november20", title: "Ảnh Hài Kịch Lớp 10", images: [
      { id: 1, src: "https://i.imgur.com/Xb3Vbj0.jpeg", alt: "Ảnh 1" },
      { id: 2, src: "https://i.imgur.com/6mlGGSE.jpeg", alt: "Ảnh 2" },
      { id: 3, src: "https://i.imgur.com/yA59454.jpeg", alt: "Ảnh 3" },
      { id: 4, src: "https://i.imgur.com/lAI1ogu.jpeg", alt: "Ảnh 4" },
      { id: 5, src: "https://i.imgur.com/sSiTHRQ.jpeg", alt: "Ảnh 5" },
      { id: 6, src: "https://i.imgur.com/ri8mWHE.jpeg", alt: "Ảnh 6" },
      { id: 7, src: "https://i.imgur.com/E6usf2B.jpeg", alt: "Ảnh 7" },
      { id: 8, src: "https://i.imgur.com/DtbITNJ.jpeg", alt: "Ảnh 8" },
      { id: 9, src: "https://i.imgur.com/9gcexLy.jpeg", alt: "Ảnh 9" },
      { id: 10, src: "https://i.imgur.com/QNdloFC.jpeg", alt: "Ảnh 10" },
      { id: 11, src: "https://i.imgur.com/Km6m2nt.jpeg", alt: "Ảnh 11" },
      { id: 12, src: "https://i.imgur.com/oftDH3V.jpeg", alt: "Ảnh 12" },
    ]},
    { id: "nv20", title: "Ảnh 20 - 11 Lớp 10", images: [
      { id: 1, src: "https://i.imgur.com/6v3Ey0Q.jpeg", alt: "Ảnh 1" },
      { id: 2, src: "https://i.imgur.com/FSRLVt0.jpeg", alt: "Ảnh 2" },
      { id: 3, src: "https://i.imgur.com/mQELUxE.jpeg", alt: "Ảnh 3" },
      { id: 4, src: "https://i.imgur.com/brj8PTh.jpeg", alt: "Ảnh 4" },
      { id: 5, src: "https://i.imgur.com/fE69nwH.jpeg", alt: "Ảnh 5" },
      { id: 6, src: "https://i.imgur.com/B4sULJ6.jpeg", alt: "Ảnh 6" },
      { id: 7, src: "https://i.imgur.com/qEyQyRE.jpeg", alt: "Ảnh 7" },
      { id: 8, src: "https://i.imgur.com/orptKPw.jpeg", alt: "Ảnh 8" },
      { id: 9, src: "https://i.imgur.com/HJsxNv0.jpeg", alt: "Ảnh 9" },
      { id: 10, src: "https://i.imgur.com/VzgSa7K.jpeg", alt: "Ảnh 10" },
      { id: 11, src: "https://i.imgur.com/evGaT7K.jpeg", alt: "Ảnh 11" },
      { id: 12, src: "https://i.imgur.com/vK2Y60U.jpeg", alt: "Ảnh 12" },
      { id: 13, src: "https://i.imgur.com/C5VlhxK.jpeg", alt: "Ảnh 13" },
      { id: 14, src: "https://i.imgur.com/03SvN7x.jpeg", alt: "Ảnh 14" },
      { id: 15, src: "https://i.imgur.com/BPQimfr.jpeg", alt: "Ảnh 15" },
      { id: 16, src: "https://i.imgur.com/ApimoNM.jpeg", alt: "Ảnh 16" },
      { id: 17, src: "https://i.imgur.com/nKY84TS.jpeg", alt: "Ảnh 17" },
      { id: 18, src: "https://i.imgur.com/FleX68b.jpeg", alt: "Ảnh 18" },
      { id: 19, src: "https://i.imgur.com/gm4sqIc.jpeg", alt: "Ảnh 19" },
    ]},
    { id: "gvdg", title: "Ảnh Vui Nhất Kì I Lớp 10", images: [
      { id: 1, src: "https://i.imgur.com/Z8lQ5nL.jpeg", alt: "Other 1" },
      { id: 2, src: "https://i.imgur.com/DNlD0ce.jpeg", alt: "Other 2" },
      { id: 3, src: "https://i.imgur.com/RdyikfP.jpeg", alt: "Other 3" },
      { id: 4, src: "https://i.imgur.com/soo5Abd.jpeg", alt: "Other 4" },
    ]},
    { id: "m4tet", title: "Ảnh Tết Lớp 10", images: [
      { id: 1, src: "https://i.imgur.com/xwB7gPU.jpeg", alt: "Other 1" },
      { id: 2, src: "https://i.imgur.com/WNGXVH6.jpeg", alt: "Other 2" },
      { id: 3, src: "https://i.imgur.com/qEDqunj.jpeg", alt: "Other 3" },
      { id: 4, src: "https://i.imgur.com/v8qLoiG.jpeg", alt: "Other 4" },
    ]},
    { id: "vnghe", title: "Ảnh - Video Văn Nghệ Lớp 10", images: [
      { id: 1, src: "https://i.imgur.com/WI7TirJ.jpeg", alt: "Other 1" },
      { id: 2, src: "https://i.imgur.com/u9MMGMN.jpeg", alt: "Other 2" },
      { id: 3, src: "https://i.imgur.com/9R3HlNh.jpeg", alt: "Other 3" },
      { id: 4, src: "https://i.imgur.com/zsp9eeh.jpeg", alt: "Other 4" },
      { id: 5, src: "https://i.imgur.com/mclTPFW.jpeg", alt: "Other 5" },
      { id: 6, src: "https://i.imgur.com/NxBRiD2.jpeg", alt: "Other 6" },
      { id: 7, src: "https://i.imgur.com/J9xS7Sb.jpeg", alt: "Other 7" },
      { id: 8, src: "https://i.imgur.com/i0jvYRB.jpeg", alt: "Other 8" },
      { id: 9, src: "https://i.imgur.com/41cG9PB.jpeg", alt: "Other 9" },
      { id: 10, src: "https://i.imgur.com/HTWCZbn.jpeg", alt: "Other 10" },
    ], youtubeUrl: "https://www.youtube.com/watch?v=c_SPOewMZfg" }, // Video cho lớp 10
    { id: "vnghe1", title: "Ảnh - Video Văn Nghệ Lớp 11", images: [
      { id: 1, src: "https://i.imgur.com/EGDY77K.jpeg", alt: "Other 1" },
    { id: 2, src: "https://i.imgur.com/5Wu9x2u.jpeg", alt: "Other 2" },
    { id: 3, src: "https://i.imgur.com/U4W6O4O.jpeg", alt: "Other 3" },
    { id: 4, src: "https://i.imgur.com/E3dr5iV.jpeg", alt: "Other 4" },
    { id: 5, src: "https://i.imgur.com/5MxPBTZ.jpeg", alt: "Other 5" },
    { id: 6, src: "https://i.imgur.com/uyeKTL6.jpeg", alt: "Other 6" },
    { id: 7, src: "https://i.imgur.com/VTQuCVb.jpeg", alt: "Other 7" },
    { id: 8, src: "https://i.imgur.com/lhaZSUe.jpeg", alt: "Other 8" },
    { id: 9, src: "https://i.imgur.com/KjOR09n.jpeg", alt: "Other 9" },
    { id: 10, src: "https://i.imgur.com/RbYsXLd.jpeg", alt: "Other 10" },
    { id: 11, src: "https://i.imgur.com/xff6tz8.jpeg", alt: "Other 11" },
    { id: 12, src: "https://i.imgur.com/ISN5hhy.jpeg", alt: "Other 12" },
    { id: 13, src: "https://i.imgur.com/srvI0Ca.jpeg", alt: "Other 13" },
    { id: 14, src: "https://i.imgur.com/wj2pycZ.jpeg", alt: "Other 14" },
    ], youtubeUrl: "https://www.youtube.com/watch?v=0JW5w1byg48" }, 
    { id: "20-10", title: "Ảnh 20-10 Lớp 11", images: [
      { id: 1, src: "https://i.imgur.com/PyrYXFG.jpeg", alt: "Other 1" },
{ id: 2, src: "https://i.imgur.com/vc1mjdi.jpeg", alt: "Other 2" },
{ id: 3, src: "https://i.imgur.com/pyasYhn.jpeg", alt: "Other 3" },
{ id: 4, src: "https://i.imgur.com/bbLos13.jpeg", alt: "Other 4" },
{ id: 5, src: "https://i.imgur.com/ZAwZ1Pk.jpeg", alt: "Other 5" },
{ id: 6, src: "https://i.imgur.com/ZWPB1IS.jpeg", alt: "Other 6" },
{ id: 7, src: "https://i.imgur.com/AWgUlD7.jpeg", alt: "Other 7" },
{ id: 8, src: "https://i.imgur.com/uBCuJxa.jpeg", alt: "Other 8" },
{ id: 9, src: "https://i.imgur.com/AKyZ4zr.jpeg", alt: "Other 9" },
{ id: 10, src: "https://i.imgur.com/kwaz15H.jpeg", alt: "Other 10" },
{ id: 11, src: "https://i.imgur.com/NMh3G6g.jpeg", alt: "Other 11" },
{ id: 12, src: "https://i.imgur.com/YustZ4E.jpeg", alt: "Other 12" },
{ id: 13, src: "https://i.imgur.com/IMdo2fO.jpeg", alt: "Other 13" },
{ id: 14, src: "https://i.imgur.com/W4hyl1S.jpeg", alt: "Other 14" },
{ id: 15, src: "https://i.imgur.com/DORRBPC.jpeg", alt: "Other 15" },
{ id: 16, src: "https://i.imgur.com/La7vLTO.jpeg", alt: "Other 16" },
{ id: 17, src: "https://i.imgur.com/yQ2hiOK.jpeg", alt: "Other 17" },
{ id: 18, src: "https://i.imgur.com/qdMqpu7.jpeg", alt: "Other 18" },
{ id: 19, src: "https://i.imgur.com/mBq69WZ.jpeg", alt: "Other 19" }

    ]},
  ];
  const news = [
  {
    id: 1,
    title: "ĐOÀN TRƯỜNG THPT VĨNH LINH TỔ CHỨC ĐẠI HỘI ĐẠI BIỂU ĐOÀN THANH NIÊN CỘNG SẢN HCM NHIỆM KỲ 2024-2025",
    image: "https://i.imgur.com/Co3hP37.jpeg",
    content: "Chi tiết về Đại hội Đại biểu Đoàn Thanh niên Cộng sản HCM nhiệm kỳ 2024-2025 tại trường THPT Vĩnh Linh...",
    fullContent: (
      <div>
        <p>Hòa trong không khí háo hức đón chào năm học mới, chiều ngày 28/9/2024, Đoàn trường THPT Vĩnh Linh đã tổ chức Đại hội đại biểu Đoàn TNCS HCM nhiệm kỳ 2024-2025.</p>
        <br />
        <p>Tham dự đại hội có đồng chí Lê Minh Thái- Huyện ủy viên, UVBCH tỉnh đoàn, Bí thư huyện đoàn Vĩnh Linh; đại diện đoàn các cơ sở: công an huyện Vĩnh Linh, trung tâm GDNN- GDTX Vĩnh Linh, Đoàn trường THCS&THPT Bến Hải. Về phía nhà trường có thầy giáo Lê Hoàng Bắc- Phó hiệu trưởng, thầy giáo Nguyễn Thanh Sơn- Chủ tịch công đoàn; về phía Hội CMHS có bác Nguyễn Trường Vân- Trưởng ban đại diện. Đại hội còn có sự tham gia của các thầy cô giáo là tổ trưởng, tổ phó chuyên môn; 31 giáo viên chủ nhiệm và đặc biệt là 143 đoàn viên là hạt nhân ưu tú của các chi đoàn.</p>
        <br />
        <p>Các tiết mục văn nghệ chào mừng của các chi đoàn học sinh và đội văn nghệ xung kích đã đem đến cho đại hội nhiều sắc màu, ca ngợi tình yêu đất nước, thể hiện khát vọng cống hiến của tuổi trẻ.</p>
        <br />
        <p> Đồng chí Trần Cảnh Tuấn- Bí thư đoàn trường trình bày: “Dự thảo Báo cáo tổng kết công tác đoàn, phong trào thanh niên trường học nhiệm kỳ 2023-2024 và phương hướng nhiệm kỳ 2024-2025”. Bản báo cáo đã đánh giá lại hoạt động của đoàn thanh niên nhà trường trong nhiệm kỳ qua và đề ra phương hướng hoạt động cùng giải pháp thực hiện công tác đoàn và phong trào thanh niên trong nhiệm kỳ mới với chủ đề: “Tuổi trẻ trường THPT Vĩnh Linh tự hào tiếp bước truyền thống anh hùng, nâng cao kỷ cương, trách nhiệm, tích cực đổi mới sáng tạo”.</p>
        <br />
        <p>Các đoàn viên thanh niên đã đóng góp cho đại hội những đề xuất, những giải pháp để nâng cao hiệu quả học tập; phát huy hiệu quả của các CLB đội nhóm trong nhà trường. </p>
        <br />
        <p> Đồng chí Lê Minh Thái- Huyện ủy viên, UVBCH tỉnh đoàn, Bí thư huyện đoàn Vĩnh Linh đã phát biểu ý kiến chỉ đạo đại hội. Đồng chí ghi nhận và biểu dương các thành tích nổi bật mà đoàn trường đạt được trong nhiệm kỳ qua, đồng thời chỉ đạo tổ chức đoàn cần phát huy hơn nữa vai trò của truyền thông trong công tác đoàn và trong định hướng tư tưởng, lối sống cho thế hệ trẻ.</p>
        <br />
        <p>Để ghi nhận sự phấn đấu không ngừng và những kết quả ấn tượng của các tổ chức, cá nhân;  đại hội đã trao cờ thi đua đơn vị xuất sắc dẫn đầu khối THPT tỉnh Quảng Trị cho đoàn trường; giấy khen của Tỉnh đoàn, giấy khen của Huyện đoàn cho các đồng chí đoàn viên đạt nhiều thành tích xuất sắc.</p>
        <br />
        <p>Đồng chí Trần Cảnh Tuấn tiếp tục được bầu làm Bí thư đoàn trường nhiệm kỳ 2024-2025. BCH đoàn trường nhiệm kỳ 2024-2025 ra mắt và nhận nhiệm vụ cho nhiệm kỳ mới.</p>
        <div className="grid grid-cols-2 gap-4 mt-6">
          <img src="https://i.imgur.com/SPnM87N.jpeg" alt="Đại hội 1" className="w-full h-48 object-cover rounded-lg" />
          <img src="https://i.imgur.com/HRncIBN.jpeg" alt="Đại hội 2" className="w-full h-48 object-cover rounded-lg" />
          <img src="https://i.imgur.com/RWAMExQ.jpeg" alt="Đại hội 2" className="w-full h-48 object-cover rounded-lg" />
          <img src="https://i.imgur.com/g2t1gbk.jpeg" alt="Đại hội 2" className="w-full h-48 object-cover rounded-lg" />
          <img src="https://i.imgur.com/lEQ5ETH.jpeg" alt="Đại hội 2" className="w-full h-48 object-cover rounded-lg" />
          <img src="https://i.imgur.com/8kZyafw.jpeg" alt="Đại hội 2" className="w-full h-48 object-cover rounded-lg" />
          <img src="https://i.imgur.com/fTajpXs.jpeg" alt="Đại hội 2" className="w-full h-48 object-cover rounded-lg" />
          <img src="https://i.imgur.com/QSliLo8.jpeg" alt="Đại hội 2" className="w-full h-48 object-cover rounded-lg" />
          <img src="https://i.imgur.com/m28HSEg.jpeg" alt="Đại hội 2" className="w-full h-48 object-cover rounded-lg" />
          <img src="https://i.imgur.com/7YRyRYx.jpeg" alt="Đại hội 2" className="w-full h-48 object-cover rounded-lg" />
          <img src="https://i.imgur.com/8TUmEcd.jpeg" alt="Đại hội 2" className="w-full h-48 object-cover rounded-lg" />
        </div>
        <p className="mt-4">Sự thành công của Đại hội đại biểu Đoàn TNCS HCM nhiệm kỳ 2024-2025  đã thể hiện sự đồng lòng, ý chí quyết tâm thực hiện thắng lợi các nhiệm vụ của đoàn viên, thanh niên trong nhà trường.</p>
      </div>
    ),
  },
  {
    id: 2,
    title: "Điểm đặc sắc trong chương trình “Vĩ tuyến 17-Khát vọng Hòa bình”",
    image: "https://i.imgur.com/iVMHXbL.png",
    content: "Chi tiết về Điểm đặc sắc trong chương trình “Vĩ tuyến 17-Khát vọng Hòa bình”...",
    fullContent: (
      <div>
        <p> Sau gần một tháng tạm hoãn, chương trình nghệ thuật chính luận “Vĩ tuyến 17 -Khát vọng Hòa bình” do Báo Nhân Dân phối hợp với tỉnh Quảng Trị tổ chức sẽ diễn ra vào tối 16/8 tại Khu Di tích Đôi bờ Hiền Lương, huyện Vĩnh Linh, tỉnh Quảng Trị. Khoảng 500 nghệ sĩ đã luyện tập hăng say suốt tuần qua để mang tới những tiết mục nghệ thuật đặc sắc phục vụ hàng chục nghìn bà con nhân dân Quảng Trị.</p>
        <br />
        <p>Chương trình nghệ thuật chính luận “Vĩ tuyến 17 - Khát vọng Hòa bình” do Báo Nhân Dân phối hợp với tỉnh Quảng Trị tổ chức nhằm ôn lại những năm tháng chia cắt hai miền nam - bắc. Hai mươi năm đau thương, mất mát, hy sinh càng cháy lên khát vọng hòa bình, thống nhất non sông trong từng con người Việt Nam, nhất là đồng bào vùng giới tuyến.</p>
        <br />
        <p>Đạo diễn Việt Đặng và ê-kíp thực hiện chương trình đã mất hơn 2 tuần để hình thành khối nội dung, xây dựng kịch bản và 1,5 tháng để tìm ra nét chính của câu chuyện lịch sử, đưa vào sân khấu nghệ thuật. Khoảng 500 diễn viên, trong đó có khoảng 300 diễn viên quần chúng là bà con địa phương, cũng đã luyện tập không ngừng để kể lại câu chuyện lịch sử này.</p>
        <br />
        <p>Chương trình sử dụng sân khấu đa không gian, với các tiết mục diễn ra tại nhiều điểm lịch sử như Kỳ đài và các không gian biểu diễn vệ tinh như khu vực tượng đài chiến sĩ Công an nhân dân vũ trang, cầu Hiền Lương. Cảnh diễn mang đậm chất lịch sử, tái hiện lại hình ảnh đoàn thuyền đưa các chiến sĩ và nhu yếu phẩm qua sông Bến Hải.</p>
        <br />
        <p>Chương trình nghệ thuật được chia làm 5 chương: “Những ngày tháng 7”, “Như không hề có cuộc chia ly”, “Máu và hoa”, “Nơi nhìn ra sức mạnh Việt Nam”, “Đất thép nở hoa”, với các phóng sự được xây dựng công phu, cùng các tiết mục hát múa dân ca miền trung và hoạt cảnh chào cờ ấn tượng.</p>
        <br />
        <p>Vở kịch “Chung một màu da” tái hiện cảm xúc phân ly và vỡ òa của người dân hai miền giới tuyến, trong khi vở kịch “Chúng ta là người nhà” mang màu sắc vui vẻ, kể về tình yêu của chàng lính pháo cao xạ và cô nữ dân quân trong bối cảnh cuộc chiến tranh.</p>
        <br />
        <p>Chương trình sẽ mang thông điệp hòa bình của Việt Nam ra thế giới, tái hiện hình ảnh một Việt Nam hòa bình, giàu khát vọng và hướng tới tương lai.</p>
        <div className="grid grid-cols-2 gap-4 mt-6">
        <img src="https://ibb.co/bFJdGcF" alt="Đại hội 1" className="w-full h-48 object-cover rounded-lg" />
        <img src="https://ibb.co/dr3pXcg" alt="Đại hội 2" className="w-full h-48 object-cover rounded-lg" />
        <img src="https://ibb.co/X7TwD62" alt="Đại hội 2" className="w-full h-48 object-cover rounded-lg" />
        <img src="https://ibb.co/YWv0SFq" alt="Đại hội 2" className="w-full h-48 object-cover rounded-lg" />
        <img src="https://ibb.co/nDdWPWp" alt="Đại hội 2" className="w-full h-48 object-cover rounded-lg" />
        <img src="https://ibb.co/4W5v57j" alt="Đại hội 2" className="w-full h-48 object-cover rounded-lg" />
          </div>
      </div>
    ),
  },
];


  const achievements = [
    { id: 1, title: "Giải Ba Tập Thể", content: "Cuộc Thi Trực Tuyến 70 Năm Truyền Thống Vĩnh Linh" },
    { id: 2, title: "Huy Chương Vàng", content: "Nguyễn Thị Linh Trúc - Hội khỏe Phù Đổng tỉnh Quảng Trị lần thứ VII năm 2024" },
    { id: 3, title: "Giải Nhì Tập Thể", content: "Cuộc Thi Stem Robot - Kỷ niệm 93 năm ngày thành lập Đoàn TNCS Hồ Chí Minh" },
  ];
  const handleSectionClick = (sectionId) => {
    setActiveSection(sectionId);
    // Reset các state liên quan đến tin tức khi chuyển section
    setShowDetailedNews(false);
    setSelectedNews(null);
    // Reset các state liên quan đến album ảnh
    setSelectedAlbum(null);
    setSelectedImage(null);
  };
  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const handleNewsClick = (newsItem) => {
    setSelectedNews(newsItem);
    setShowDetailedNews(true);
  };

  const renderContent = () => {
    if (showDetailedNews) {
      return (
        <div className="bg-white p-6 rounded-lg shadow-md">
          <button
            className="mb-4 flex items-center text-blue-500 hover:text-blue-700"
            onClick={() => setShowDetailedNews(false)}
          >
            <FaArrowLeft className="mr-2" /> Quay lại
          </button>
          <h2 className="text-3xl font-bold mb-4">{selectedNews.title}</h2>
          <img src={selectedNews.image} alt={selectedNews.title} className="w-full h-64 object-cover rounded-lg mb-4" />
          <div>{selectedNews.fullContent}</div>
        </div>
      );
    }
  
    switch (activeSection) {
      case "home":
        return (
          <div className="text-center">
            <h2 className="text-3xl font-bold mb-4">Chào mừng bạn tới tập thể lớp A5 - K66</h2>
            <p className="text-xl mb-6">Luôn kết nối, hồi tưởng lại những kỷ niệm và ăn mừng thành tích của chúng ta!</p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {sections.slice(1).map((section) => (
                <div
                  key={section.id}
                  className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 cursor-pointer"
                  onClick={() => setActiveSection(section.id)}
                >
                  <div className="text-4xl mb-4">{section.icon}</div>
                  <h3 className="text-xl font-semibold">{section.title}</h3>
                </div>
              ))}
            </div>
          </div>
        );
// ... existing code ...
case "admin":
    return (
        <div>
            <iframe
                srcDoc={`
                    <!DOCTYPE html>
                    <html lang="en">
                    <head>
                        <meta charset="UTF-8">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css">
                        <style>
                            @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap');

                            * {
                                margin: 0;
                                padding: 0;
                                box-sizing: border-box;
                                text-decoration: none;
                                border: none;
                                outline: none;
                                font-family: 'Poppins', sans-serif;
                            }

                            html {
                                font-size: 62.5%;
                            }

                            body {
                                width: 100%;
                                height: 100vh;
                                overflow-x: hidden;
                                background-color: black;
                                color: white;
                            }

                            header {
                                margin-top: 20px;
                                position: fixed;
                                top: 0;
                                left: 0;
                                width: 100%;
                                padding: 1rem 9%;
                                background-color: transparent;
                                filter: drop-shadow(10px);
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                z-index: 100;
                            }

                            .logo {
                                font-size: 3rem;
                                color: #b74b4b;
                                font-weight: 800;
                                cursor: pointer;
                                transition: 0.5s ease;
                            }

                            .logo:hover {
                                transform: scale(1.1);
                            }

                            nav a {
                                font-size: 1.8rem;
                                color: white;
                                margin-left: 4rem;
                                font-weight: 500;
                                transition: 0.3s ease;
                                border-bottom: 3px solid transparent;
                            }

                            nav a:hover,
                            nav a.active {
                                color: #b74b4b;
                                border-bottom: 3px solid #b74b4b;
                            }

                            @media(max-width:995px) {
                                nav {
                                    position: absolute;
                                    display: none;
                                    top: 0;
                                    right: 0;
                                    width: 40%;
                                    border-left: 3px solid #b74b4b;
                                    border-bottom: 3px solid #b74b4b;
                                    border-bottom-left-radius: 2rem;
                                    padding: 1rem solid;
                                    background-color: #161616;
                                    border-top: 0.1rem solid rgba(0,0,0,0.1);
                                }

                                nav.active {
                                    display: block;
                                }

                                nav a {
                                    display: block;
                                    font-size: 2rem;
                                    margin: 3rem 0;
                                }

                                nav a:hover,
                                nav a.active {
                                    padding: 1rem;
                                    border-radius: 0.5rem;
                                    border-bottom: 0.5rem solid #b74b4b;
                                }
                            }

                            section {
                                min-height: 100vh;
                                padding: 5rem 9% 5rem;
                            }

                            .home {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                gap: 8rem;
                                background-color: black;
                            }

                            .home .home-content h1 {
                                font-size: 6rem;
                                font-weight: 700;
                                line-height: 1.3;
                            }

                            span {
                                color: #b74b4b;
                            }

                            .home-content h3 {
                                font-size: 4rem;
                                margin-bottom: 1rem;
                                font-weight: 700;
                            }

                            .home-content p {
                                font-size: 1.6rem;
                            }

                            .home-img {
                                border-radius: 50%;
                            }

                            .home-img img {
                                position: relative;
                                width: 32vw;
                                border-radius: 50%;
                                box-shadow: 0 0 25px solid #b74b4b;
                                cursor: pointer;
                                transition: 0.2s linear;
                            }

                            .home-img img:hover {
                                font-size: 1.8rem;
                                font-weight: 500;
                            }

                            .social-icons a {
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;
                                width: 4rem;
                                height: 4rem;
                                background-color: transparent;
                                border: 0.2rem solid #b74b4b;
                                font-size: 2rem;
                                border-radius: 50%;
                                margin: 3rem 1.5rem 3rem 0;
                                transition: 0.3s ease;
                                color: #b74b4b;
                            }

                            .social-icons a:hover {
                                color: black;
                                transform: scale(1.3) translateY(-5px);
                                background-color: #b74b4b;
                                box-shadow: 0  0 25px #b74b4b;
                            }

                            .btn {
                                display: inline-block;
                                padding: 1rem 2.8rem;
                                background-color: black;
                                border-radius: 4rem;
                                font-size: 1.6rem;
                                color: #b74b4b;
                                letter-spacing: 0.3rem;
                                font-weight: 600;
                                border: 2px solid #b74b4b;
                                transition: 0.3s ease;
                                cursor: pointer;
                            }

                            .btn:hover {
                                transform: scale3d(1.03);
                                background-color: #b74b4b;
                                color: black;
                                box-shadow: 0 0 25px #b74b4b;
                            }

                            .typing-text {
                                font-size: 34px;
                                font-weight: 600;
                                min-width: 280px;
                            }

                            .typing-text span {
                                position: relative;
                            }

                            .typing-text span::before {
                                content: "software Developer";
                                color: #b74b4b;
                                animation: words 20s infinite;
                            }

                            .typing-text span::after {
                                content: "";
                                background-color: black;
                                position: absolute;
                                width: calc(100% + 8px);
                                height: 100%;
                                border-left: 3px solid black;
                                right: -8;
                                animation: cursor 0.6s infinite;
                            }

                            @keyframes cursor {
                                to {
                                    border-left: 3px solid #b74b4b;
                                }
                            }

                            @keyframes words {
                                0%, 20% {
                                    content: "Học Sinh";
                                }
                                21%, 40% {
                                    content: "Người Chơi Đàn Nhị";
                                }
                                41%, 60% {
                                    content: "Coder";
                                }
                                61%, 80% {
                                    content: "Content Creator";
                                }
                                81%, 100% {
                                    content: "Học Sinh";
                                }
                            }

                            @media (max-width: 1000px) {
                                .home {
                                    gap: 4rem;
                                }
                            }

                            @media(max-width:995px) {
                                .home {
                                    flex-direction: column;
                                    margin: 5rem 4rem;
                                }

                                .home .home-content h3 {
                                    font-size: 2.5rem;
                                }

                                .home-content h1 {
                                    font-size: 5rem;
                                }

                                .home-img img {
                                    width: 70vw;
                                    margin-top: 4rem;
                                }
                            }
                        </style>
                        <title>Portfolio Website</title>
                    </head>
                    <body>
                        <header>
                            <!-- Xóa logo Phan Văn An -->
                            <nav>
                                <!-- Xóa Trang chủ -->
                            </nav>
                        </header>
                        <section class="home">
                            <div class="home-img">
                                <img src="https://i.imgur.com/vcTG3SQ.jpeg" alt="Main Image"> <!-- Thay thế bằng URL hình ảnh -->
                            </div>
                            <div class="home-content">
    <h1>Xin chào, tôi tên là <span>An</span></h1>
    <h3 class="typing-text">Tôi là một <span></span></h3>
    <p>Hồ sơ cá nhân</p>
    <br>
    <p> Họ Và Tên : Văn An</p>
    <br>
    <p> Sinh ngày : 24/4/2008.
    <br>
    <p>Quê quán: Quảng Trị</p>
    <br>
    <p>Đơn vị: Lớp 11A5, trường THPT Vĩnh Linh</p>
    <br>
    <p>Chức vụ: Bí thư chi đoàn 11A5</p>
    <br>
    <p> Sở thích : Làm việc trên máy tính, chơi các nhạc cụ dân tộc như đàn nhị và sáo ....
    <br>
    <img src="https://i.imgur.com/mN0GcHm.png" className="w-full h-auto mt-2" />
    <br>
</p>
    <br>
    <p>
        Lý tưởng sống của tôi là luôn phấn đấu hoàn thiện bản thân và từng ngày tiến tới một phiên bản tốt hơn của chính mình. Tôi tin rằng chỉ khi dám rời khỏi vùng an toàn, sẵn sàng đối mặt với thách thức và không ngừng trau dồi kiến thức, tôi mới có thể mở rộng giới hạn của mình. Những trải nghiệm mới không chỉ giúp tôi học hỏi mà còn mang đến những góc nhìn đa dạng về cuộc sống, giúp tôi thấu hiểu sâu sắc hơn về bản thân và thế giới xung quanh. Bên cạnh việc phát triển bản thân, tôi luôn mong muốn góp phần vào sự phát triển của cộng đồng. Với tôi, cuộc sống là hành trình vừa hoàn thiện chính mình vừa lan tỏa giá trị tích cực, hỗ trợ người khác và tạo nên những mối liên kết ý nghĩa. Sự chia sẻ và tinh thần đoàn kết là nền tảng cho một xã hội nhân văn, phát triển, và tôi hy vọng mình sẽ góp phần lan tỏa tinh thần ấy trong cuộc sống.
    </p>
                                <div class="social-icons">
                                    <a href="https://www.facebook.com/a5k66" target="_blank" rel="noopener noreferrer">
    <i class="fab fa-facebook-f"></i>
</a>
<a href="https://www.tiktok.com/@thptvinhlinha5k66" target="_blank" rel="noopener noreferrer">
    <i class="fa-brands fa-tiktok"></i>
</a>
<a href="https://www.youtube.com/@THPTVINHLINHA5-K66" target="_blank" rel="noopener noreferrer">
    <i class="fa-brands fa-youtube"></i>
</a>
<a href="https://www.instagram.com/_a5.k66_/" target="_blank" rel="noopener noreferrer">
    <i class="fa-brands fa-instagram"></i>
</a>
                                </div>
                                <!-- Xóa nút Quay lại -->
                            </div>
                        </section>
                    </body>
                    </html>
                `}
                style={{ width: "100%", height: "100vh", border: "none" }}
            />
        </div>
    );
            case "memory":
          return (
            <div>
              <h2 className="text-3xl font-bold mb-6">Ảnh</h2>
              {selectedAlbum ? (
                <div>
                  <button
                    className="mb-4 flex items-center text-blue-500 hover:text-blue-700"
                    onClick={() => setSelectedAlbum(null)}
                  >
                    <FaArrowLeft className="mr-2" /> Quay lại
                  </button>
                  <h3 className="text-2xl font-semibold mb-4">{selectedAlbum.title}</h3>
                  {/* Thêm đoạn mã nhúng video YouTube */}
                  {selectedAlbum.youtubeUrl && (
                    <div className="mb-4">
                      <iframe
                        width="560"
                        height="315"
                        src={`https://www.youtube.com/embed/${new URL(selectedAlbum.youtubeUrl).searchParams.get('v')}`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                      ></iframe>
                    </div>
                  )}
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                    {selectedAlbum.images.map((image) => (
                      <div
                        key={image.id}
                        className="cursor-pointer overflow-hidden rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
                        onClick={() => handleImageClick(image)}
                      >
                        <img src={image.src} alt={image.alt} className="w-full h-64 object-cover" />
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                  {albums.map((album) => (
                    <div
                      key={album.id}
                      className="cursor-pointer bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
                      onClick={() => setSelectedAlbum(album)}
                    >
                      <FaFolder className="text-4xl mb-4 text-yellow-500" />
                      <h3 className="text-xl font-semibold">{album.title}</h3>
                    </div>
                  ))}
                </div>
              )}
            </div>
          );
      case "news":
        return (
          <div>
            <h2 className="text-3xl font-bold mb-6">Tin tức</h2>
            <div className="space-y-6">
              {news.map((item) => (
                <div key={item.id} className="bg-white p-6 rounded-lg shadow-md flex items-start">
                  {item.image && (
                    <img src={item.image} alt={item.title} className="w-32 h-32 object-cover rounded-lg mr-4" />
                  )}
                  <div>
                    <h3 
                      className="text-xl font-semibold mb-2 cursor-pointer hover:text-blue-500"
                      onClick={() => handleNewsClick(item)}
                    >
                      {item.title}
                    </h3>
                    <p>{item.content}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      case "achievements":
        return (
          <div>
            <h2 className="text-3xl font-bold mb-6">Thành tựu</h2>
            <div className="space-y-6">
              {achievements.map((item) => (
                <div key={item.id} className="bg-white p-6 rounded-lg shadow-md">
                  <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
                  <p>{item.content}</p>
                </div>
              ))}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-sky-100">
      <div className="container mx-auto px-4 pt-8">
        <div className="flex items-center justify-between mb-8">
          <img
            src="https://i.imgur.com/65GEyks.png"
            alt="Logo"
            className="w-32 h-32 object-contain"
          />
          <div className="flex items-center space-x-6">
            {socialLinks.map((link) => (
              <a
                key={link.id}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-3xl text-gray-600 hover:text-gray-900 transition-colors duration-300 transform hover:scale-110"
              >
                {link.icon}
              </a>
            ))}
          </div>
        </div>
        <div className="relative h-80 mb-8 rounded-lg overflow-hidden">
          <img
            src="https://i.imgur.com/UE8McNR.jpeg"
            alt="Cover Photo"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          </div>
        </div>
        <nav className="mb-8">
          <ul className="flex flex-wrap justify-center gap-4">
            {sections.map((section) => (
              <li key={section.id}>
                <button
                  className={`flex items-center px-4 py-2 rounded-full ${
                    activeSection === section.id
                      ? "bg-blue-500 text-white"
                      : "bg-white text-gray-700 hover:bg-gray-100"
                  } transition-colors duration-300`}
                  onClick={() => handleSectionClick(section.id)}
                  aria-label={`Go to ${section.title}`}
                >
                  <span className="mr-2">{section.icon}</span>
                  {section.title}
                </button>
              </li>
            ))}
          </ul>
        </nav>
        <motion.div
          key={activeSection}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
          className="bg-white rounded-lg shadow-xl p-8"
        >
          {renderContent()}
        </motion.div>
      </div>
      {selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div className="max-w-3xl max-h-full p-4">
            <img
              src={selectedImage.src}
              alt={selectedImage.alt}
              className="max-w-full max-h-full object-contain"
            />
          </div>
        </div>
      )}
      <footer className="bg-blue-800 text-white py-8 mt-12">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <h3 className="text-xl font-bold mb-4">CHI ĐOÀN 11A5</h3>
              <p>CHI ĐOÀN 11A5 - TRƯỜNG THPT VĨNH LINH</p>
              <p>61 Trần Hưng Đạo, Thị trấn Hồ Xá, Huyện Vĩnh Linh - Quảng Trị</p>
              <p className="flex items-center mt-2">
                <FaEnvelope className="mr-2" /> chidoan11a5@gmail.com
              </p>
              <p className="flex items-center mt-2">
                <FaPhone className="mr-2" /> xxxxxxxxxx
              </p>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4">Liên kết nhanh</h3>
              <ul className="space-y-2">
                <li><a href="#" className="hover:text-blue-300">Trang chủ</a></li>
                <li><a href="#" className="hover:text-blue-300">Ảnh kỉ niệm</a></li>
                <li><a href="#" className="hover:text-blue-300">Thành tựu</a></li>
                <li><a href="#" className="hover:text-blue-300">Tin tức</a></li>
                <li><a href="#" className="hover:text-blue-300"></a></li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4">Kết nối với chúng tôi</h3>
              <div className="flex space-x-4">
                {socialLinks.map((link) => (
                  <a
                    key={link.id}
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-2xl hover:text-blue-300 transition-colors duration-300"
                  >
                    {link.icon}
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-gray-700 text-center">
            <p>© 2024 Bản quyền thuộc về Bí thư chi đoàn A5</p>
            <p>Thiết kế bởi Phan Văn An</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ClassNewsPage;
