import React from 'react';
import ClassNewsPage from './components/ClassNewsPage';

function App() {
  return (
    <div className="App">
      <ClassNewsPage />
    </div>
  );
}

export default App;